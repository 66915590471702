import { motion } from "framer-motion";
import "./AboutMe.css";

export default function AboutMe(props: { isMobile: boolean; }) {
    var containerName = props.isMobile ? "about-me-container-mobile" : "about-me-container";
    var rowName = props.isMobile ? "row-mobile" : "row";

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -25 }
    }

    return (
        <div className={containerName}>
            <motion.div
                className="fadein-container"
                variants={variants}
                initial="hidden"
                whileInView="visible"
                transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
            >
                <h1>About Me</h1>
                <section className="enable-animation">
                    <div className="marquee">
                        <ul className="marquee__content">
                            <div className=" marquee__item">
                                <button>ILLUSTRATION</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>COMICS</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>WRITING</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>PHOTOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>VIDEOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>DEVELOPMENT</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className=" marquee__item">
                                <button>ILLUSTRATION</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>COMICS</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>WRITING</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>PHOTOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>VIDEOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>DEVELOPMENT</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                        </ul>

                        <ul aria-hidden="true" className="marquee__content">
                            <div className=" marquee__item">
                                <button>ILLUSTRATION</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>COMICS</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>WRITING</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>PHOTOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>VIDEOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>DEVELOPMENT</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className=" marquee__item">
                                <button>ILLUSTRATION</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>COMICS</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>WRITING</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>PHOTOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>VIDEOGRAPHY</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                            <div className="marquee__item">
                                <button>DEVELOPMENT</button>
                            </div>
                            <div className="marquee__item">
                                <button style={{ color: "#9dc6c7" }}>{"//"}</button>
                            </div>
                        </ul>
                    </div>
                </section>

                <div className={rowName}>
                    <div className="column">
                        <h2>
                            Living Vicariously
                        </h2>
                        <p>
                            UBC Computer Science student that mostly draws. Originally I started with purely illustration but I took too long to draw so I started drawing some quick dumb comics so people wouldn't think I'm dead. Now I spend too long drawing comics.
                            Occasionally I'll do some code stuff as well (like this website).
                        </p>
                        <p>
                            <i>
                                This website is a WIP: my art and comics are mostly on Twitter and Pixiv.
                            </i>
                        </p>
                    </div>
                </div>
            </motion.div>
            <div style={{ textAlign: "right" }}>
                <img
                    src="/images/oogy+cats.png"
                    alt="oogy"
                    style={{
                        height: "25%",
                        width: "25%",
                        marginTop: -100,
                        marginRight: "30%"
                    }}
                />
            </div>
        </div>
    );
}