import AboutMe from "../Components/Home/AboutMe";
import HeroSection from "../Components/Home/HeroSection";
import { isMobile } from 'react-device-detect';

export default function Home() {
    return (
        <>
            <HeroSection />
            <AboutMe isMobile = {isMobile}/>
        </>
    );
}