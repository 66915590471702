import { createBrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";

export const router = createBrowserRouter( [
    {
        path: "/",
        element: <Home/>   
    },
    {
        path: "/comics/:comicName/:chapterNum",
        element: <div/>
    },
    {
        path: "/gallery/:year",
        element: <div/>
    },
    {
        path: "/blog/:pageNum",
        element: <div/>
    },
    {
        path: "/thanks",
        element: <div/>
    }
] )