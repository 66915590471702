import { IconButton, ThemeProvider } from '@mui/material';
import './HeroSection.css';
import { FreeBreakfast, Instagram, Mail, X, Collections } from '@mui/icons-material';
import { createTheme } from '@mui/material';
import { teal } from '@mui/material/colors';
import { motion, useAnimate, useMotionValueEvent, useScroll } from 'framer-motion';
import React, { useEffect } from 'react';

const theme = createTheme({
    palette: {
        primary: {
            main: '#fffbf7',
        },
        secondary: teal,
    },
})

export default function HeroSection() {
    const { scrollYProgress } = useScroll();
    const [scope, animate] = useAnimate();
    const [hidden, setHidden] = React.useState(false);

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        latest > 0 ? setHidden(true) : setHidden(false);
    })

    const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    }

    async function bounceAnimation() {
        await animate(scope.current, { opacity: 0 });
        await animate(scope.current, { opacity: 1 }, { delay: 2 });
        animate(
            scope.current,
            {
                y: ["0%", "100%", "0%"]
            },
            {
                repeat: Infinity,
                ease: "easeInOut",
                duration: 2
            }
        )
    }

    useEffect(() => {
        bounceAnimation();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="hero-container">
            <img className="hero" src="/images/HeroSection.png" alt="Hero Banner" />
            <motion.h1
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
            >hello</motion.h1>
            <motion.p
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
            >i'm oogy</motion.p>
            <ThemeProvider theme={theme}>
                <motion.div
                    className="container-side-by-side"
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                >
                    <IconButton href="https://twitter.com/spoogyoogie" target="_blank" aria-label="twitter" size="large" color="primary">
                        <X fontSize="large" />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/spoogyoogie/" target="_blank" aria-label="instagram" size="large" color="primary">
                        <Instagram fontSize="large" />
                    </IconButton>
                    <IconButton href="https://ko-fi.com/spoogyoogie" target="_blank" aria-label="kofi" size="large" color="primary">
                        <FreeBreakfast fontSize="large" />
                    </IconButton>
                    <IconButton href="https://www.pixiv.net/en/users/32040454" target="_blank" aria-label="mail" size="large" color="primary">
                        <Collections fontSize="large" />
                    </IconButton>
                    <IconButton href="mailto:spoogyoogie@gmail.com" target="_blank" aria-label="mail" size="large" color="primary">
                        <Mail fontSize="large" />
                    </IconButton>
                </motion.div>
            </ThemeProvider>
            <motion.div
                className="triangle-container"
                variants={variants}
                animate={hidden ? "hidden" : "visible"}
                transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
            >
                <motion.div
                    ref={scope}
                    className='triangle-down'
                />
            </motion.div>
        </div>
    );
}